
















































import { Inject } from '@cds/common';
import { Component, Vue } from 'vue-property-decorator';
import { BonusService } from '@/services/bonus-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';
@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
  public form: any = {};
  public tableData: any[] = [];
  public loading: Boolean = true;
  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    await this.getList();
  }

  //搜索
  public search() {
    this.form.current = 1;  
    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.bonusService.awardsContentList(this.form || {});
    this.tableData = res.records || [];
    this.form.total = res.total;
    this.form.size = res.size;
    this.form.current = res.current;
    this.loading = false;
  }
  //新增
  public async handAdd(){
    this.clearLocalStorage();
    this.$router.push('/detailsNewContent');
  }
  //删除
  private async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      const res: any = await this.bonusService.awardsContentDelete(param);
      if (res) {
        this.getList()
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      }
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //清空本地缓存
  clearLocalStorage(){
    localStorage.removeItem('detailsContentList');
    localStorage.removeItem('detailsContentForm');
    localStorage.removeItem('newContentList');
    localStorage.removeItem('newContentForm');
    localStorage.removeItem('deleteIds');
  }
  //修改
  public async handDetails(row: any){
    this.clearLocalStorage();
    this.$router.push({
      path: '/detailsContent',
      query: { id:  row.id }
    });
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search()
  }
  
}
